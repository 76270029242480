<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="talent-page">
		<DirectorsList />
		<video v-if="talent" id="videoContainer" :src="apiUrl + talent.initialCover.data[0].attributes.url"
			crossorigin="anonymous" muted playsinline loop autoplay></video>
	</div>
</template>

<script>
import DirectorsList from '@/components/DirectorsList'
import { E } from '@/assets/js/utils'
import store from '@/assets/js/store'
import axios from 'axios';
export default {
  name: 'Talent-page',
  components: {
	DirectorsList
  },
  props: {
    data: Object
  },
  data () {
    return {
		videoTitle: 'tempVid',
		talent: false,
		apiUrl: process.env.VUE_APP_API,
	}
  },
  async created () {
	try {
      const response = await axios.get(`${process.env.VUE_APP_API}/api/talent-page?populate=*`)
      this.talent = response.data.data.attributes
    } catch (error) {
      this.error = error;
    }
  },
  mounted () {
	E.emit('PageSwitch')
	if(store.isLoaded) store.Lenis.dimensions.resize()
  },
  methods: {
  }
}
</script>
<style lang="less">
@import '../../assets/less/_imports.less';
@import "./style.less";
</style>