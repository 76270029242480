import store from './store'
import { Vector2 } from 'three'
import { E, qs } from './utils'
import GlobalEvents from './utils/GlobalEvents'
import gsap from 'gsap'
export default class CursorElement{
	constructor(){
		this.tempPos = new Vector2()
		this.position = new Vector2()
		this.tempVelocity = 0
		this.lerp = 0.2
		this.velocity = 0
		this.cursorRotation = 0
		this.el = qs('#cursorElement')
		this.body = qs('.scroll-container')
		this.link = qs('.scroll-container a')
		this.el.style.setProperty('--scaleBefore', 1);
		this.el.style.setProperty('--scaleAfter', 1);
		this.el.style.setProperty('--opacity', 1);
		this.hideVal = [{val: 1}, {val: 1}, {val: 1}]
		// this.start()
		// E.on('cursorStart', this.start)
		// E.on('cursorStop', this.stop)
	}
	start = () => {
		gsap.to(this.el, {opacity: 1})
		store.RAFCollection.add(this.onRaf, 1)
		E.on(GlobalEvents.MOUSEMOVE, this.setTemp)
		E.on('TeaseEnter', this.triggerRotate)
		this.body.classList.add('isCursor')

		this.bodyLeave = this.body.addEventListener('mouseleave', () => {
			this.el.classList.add('remove')
			gsap.killTweensOf(this.hideVal)
			gsap.to(this.hideVal, { val: 0, stagger: 0.1, onUpdate: () => {
				this.el.style.setProperty('--scaleAfter', this.hideVal[0].val);
				this.el.style.setProperty('--opacity', this.hideVal[1].val);
				this.el.style.setProperty('--scaleBefore', this.hideVal[2].val);
			}})
		})

		this.bodyEnter = this.body.addEventListener('mouseenter', () => {
			this.el.classList.remove('remove')
			gsap.killTweensOf(this.hideVal)
			gsap.to(this.hideVal, { val: 1, stagger: -0.1, onUpdate: () => {
				this.el.style.setProperty('--scaleAfter', this.hideVal[0].val);
				this.el.style.setProperty('--opacity', this.hideVal[1].val);
				this.el.style.setProperty('--scaleBefore', this.hideVal[2].val);
			}})
		})

		if(this.link){
			this.linkLeave = this.link.addEventListener('mouseenter', () => {
				this.el.classList.add('remove')
				gsap.killTweensOf(this.hideVal)
				gsap.to(this.hideVal, { val: 0, stagger: 0.1, onUpdate: () => {
					this.el.style.setProperty('--scaleAfter', this.hideVal[0].val);
					this.el.style.setProperty('--opacity', this.hideVal[1].val);
					this.el.style.setProperty('--scaleBefore', this.hideVal[2].val);
				}})
			})
	
			this.linkEnter = this.link.addEventListener('mouseleave', () => {
				this.el.classList.remove('remove')
				gsap.killTweensOf(this.hideVal)
				gsap.to(this.hideVal, { val: 1, stagger: -0.1, onUpdate: () => {
					this.el.style.setProperty('--scaleAfter', this.hideVal[0].val);
					this.el.style.setProperty('--opacity', this.hideVal[1].val);
					this.el.style.setProperty('--scaleBefore', this.hideVal[2].val);
				}})
			})
		}
	}

	appear(){
		gsap.killTweensOf(this.hideVal)
		if(store.mousePos){
			this.tempPos.copy(store.mousePos)
		}
		this.el.style.setProperty('--rotation', this.cursorRotation + 'deg');

		gsap.to(this.hideVal, { val: 1, stagger: -0.1, onUpdate: () => {
			this.el.style.setProperty('--scaleAfter', this.hideVal[0].val);
			this.el.style.setProperty('--opacity', this.hideVal[1].val);
			this.el.style.setProperty('--scaleBefore', this.hideVal[2].val);
		}})
	}
	stop = () => {
		gsap.killTweensOf(this.hideVal)
		this.body.removeEventListener('mouseleave', this.bodyLeave)
		this.body.removeEventListener('mouseenter', this.bodyLeave)
		gsap.to(this.hideVal, { val: 0, stagger: -0.1, onUpdate: () => {
			this.el.style.setProperty('--scaleAfter', this.hideVal[0].val);
			this.el.style.setProperty('--opacity', this.hideVal[1].val);
			this.el.style.setProperty('--scaleBefore', this.hideVal[2].val);
		}})
		this.body.classList.remove('isCursor')
		store.RAFCollection.remove(this.onRaf, 1)
		E.off(GlobalEvents.MOUSEMOVE, this.setTemp)
	
		gsap.to(this.el, {opacity: 0})
	}

	setTemp = (e) => {
		this.tempPos.set(e.clientX, e.clientY)
	}

	triggerRotate = () => {
		this.tempVelocity += 360
	}

	onRaf = () => {
		let v3 = new Vector2()
		v3.copy(this.tempPos)

		v3.sub(this.position)
		v3.multiplyScalar(this.lerp)
		this.position.add(v3)
		// this.tempVelocity = Math.min(this.position.distanceTo(this.tempPos), 50) * Math.sign(this.tempPos.x - this.position.x)

		this.velocity += (this.tempVelocity - this.velocity) * 0.05
		this.cursorRotation = this.velocity
		this.el.style.setProperty('--rotation', this.cursorRotation + 'deg');
		this.el.style.transform = `translate(calc(${this.position.x}px - 50%), calc(${this.position.y}px - 50%))`



	}
}