<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<li class="projects-tease" @mouseenter="onEnter">
		<img v-if="data.attributes.Thumbnail.data.attributes.ext.match((/\.(png|jpg|jpeg)$/))" :src="apiUrl + data.attributes.Thumbnail.data.attributes.url" alt="">
		<video ref="video" v-else :src="apiUrl + data.attributes.Thumbnail.data.attributes.url" crossorigin="anonymous" muted playsinline loop></video>
		<div class="projects-data" ref="textContent">
			<h2 class="projects-name project-title" ref="title">
				{{ data.attributes.name }}<br/>
				{{ data.attributes.subtitle }}
			</h2>
			<h3 class="projects-director project-director" ref="directorContainer" ><span ref="directorName" v-html="data.attributes.director.data.attributes.name.replaceAll('-', '&#8209;')"></span></h3>
		</div>
		
	</li>
</template>
<script>
import store from '@/assets/js/store'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitType from 'split-type'
import gsap from 'gsap'
import E from '@/assets/js/utils/E'
import GlobalEvents from '@/assets/js/utils/GlobalEvents'
gsap.registerPlugin(ScrollTrigger)
export default {
  name: 'projectTease-comp',
  components: {},
  props: {
    data: Object
  },
  data () {
    return {
		apiUrl: process.env.VUE_APP_API,
		testName: 'Salomé Gomis-Trezise'
	}
  },
  created () {
  },
  mounted () {
	E.on(GlobalEvents.RESIZE, this.onResize)
	this.$refs.directorContainer.style.width = this.$refs.directorName.getBoundingClientRect().width + 'px'
	this.$nextTick(() => {
		if(store.isLoaded) {
			store.Lenis.dimensions.resize()
			this.setScrollTrigger()
			gsap.set(this.$refs.textContent, {y: 100 + '%'})
			gsap.set(this.$refs.video, {opacity: 0})
			this.$refs.video.addEventListener('canplaythrough', () => {
				gsap.to(this.$refs.video, {opacity: 1, duration: 0.5})
				gsap.to(this.$refs.textContent, {y: 0 + '%', delay: 0.2, duration: 1, ease:'power2.out'})
			})
		}
		E.on('LoaderOut',() => {
			this.setScrollTrigger()
			gsap.from(this.$refs.video, {opacity: 0, duration: 0.5})
			gsap.from(this.$refs.textContent, {y: 100 + '%', delay: 0.2, duration: 1, ease:'power2.out'})
		})
	
		this.$refs.title.split = new SplitType(this.$refs.title,{ types: 'lines' })
		// this.$refs.title.split.lines.forEach(el => {
		// 	this.spanify(el)
		// });
	})
  },
  beforeUnmount() {
		E.off(GlobalEvents.RESIZE, this.onResize)
		this.scrollText.kill()
		this.scrollVideo.kill()
		// this.textAppear.kill() 
		this.titleAppear.kill()
	},
  methods: {
	setScrollTrigger(){
		this.scrollText =  ScrollTrigger.create({
			trigger:this.$el,
			start: "center top",
			end: "bottom top",
			scrub: 1,
			onUpdate: (e) => {
				if(this.$refs.textContent){
					this.$refs.textContent.style.transform = `translate3D(0, ${this.easeInSine(e.progress) * store.window.h * 0.1}px,0)`
				}
			},
			onLeave:() => {
				this.$refs.video.pause()
			},
			onEnterBack:() => {
				this.$refs.video.play()
			}
		})
		this.scrollVideo =  ScrollTrigger.create({
			trigger:this.$el,
			start: "top bottom",
			end: "top center",
			scrub: 1,
			onEnter: () => {
				this.$refs.video.play()
			},
			onUpdate: (e) => {
				if(this.$refs.video){
					this.$refs.video.style.filter = `blur(${(1 - e.progress) * 50}px)`
				}
			}
		})
		// this.textAppear =  ScrollTrigger.create({
		// 	trigger:this.$el,
		// 	start: "top bottom",
		// 	end: "top center",
		// 	scrub: 1,
		// 	onEnter: () => {
		// 		this.$refs.video.play()
		// 	},
		// 	onUpdate: (e) => {
		// 		if(this.$refs.video){
		// 			this.$refs.video.style.filter = `blur(${(1 - e.progress) * 50}px)`
		// 		}
		// 	}
		// })
		this.titleAppear =  ScrollTrigger.create({
			trigger:this.$refs.title,
			start: "top bottom",
			end: "top center",
			scrub: 1,
			onEnter: () => {
				gsap.from(this.$refs.title.split.lines, { y: 100 + '%', stagger: 0.2, ease: 'power2.out'})
			}
		})
	},
	easeInSine(x) {
		return 1 - Math.cos((x * Math.PI) / 2);
	},

	onResize() {
		this.$refs.directorContainer.style.width = 'auto'
		this.$nextTick(() => {
			this.$refs.directorContainer.style.width = this.$refs.directorName.getBoundingClientRect().width + 'px'
		})
	},

	onEnter(){
		E.emit('TeaseEnter')
	}

	// spanify(el){
	// 	let out = ''
	// 	const char = el.textContent.split('')
	// 	char.forEach((char, i) => {
	// 		out += `<span class="char" style="transition-delay=${i * 0.05}s">`
	// 		out += char
	// 		out +='</span>'
	// 	})
	// 	el.innerHTML = out
	// }
  }
}
</script>
<style lang="less">
	@import (once)'../../assets/less/_imports.less';
	@import "./style.less";
</style>