import { Group, WebGLRenderTarget, Raycaster, PlaneGeometry, Mesh, Vector2, MeshBasicMaterial } from 'three'
import {  TokenMaterial, BackFaceToken } from '../materials'
import store from '../store'
import { E } from '../utils'
import GlobalEvents from '../utils/GlobalEvents'
import gsap from 'gsap'
export default class Logo extends Group {
	constructor() {
		super()
		this.vel = new Vector2()
		this.load()
		this.renderOrder = 20
		this.introProgressTemp = 0
		this.introProgress = 0
		this.tokenChange = true
		this.isEnter = false
		this.position.set(-9, 0, 11)
		this.rotation.set(0, -1.5, 0)
		this.rY = 0
		this.targetRotation = 0

		this.baseConfig = {
			uIOR: 2,
			uFresnelVal: 0,
			uRefractPower: 0.7
		}
		this.contactConfig = {
			uIOR: 1.391,
			uFresnelVal: 0.543,
			uRefractPower: 1
		}
		this.motoConfig = {
			uIOR: 1.391,
			uFresnelVal: 0.543,
			uRefractPower: 1
		}
	}
	build() {
		// this.createSingle(0)
		this.createSingle(1)

		this.mouseInteraction()
		
		store.RAFCollection.add(this.onRaf, 0)
		// this.rotation.set(-.5, 0, 0)
		// this.position.set(0, 0, -5)
		E.on(GlobalEvents.RESIZE, this.onResize)
		// E.on('LoaderOut', () => {
		// 	this.appear()
		// 	E.on(GlobalEvents.RESIZE, this.onResize)
		// 	gsap.delayedCall(.5, () => {
		// 		this.appearTimeline.play()
		// 	})
		// })
		E.on('introProgress', (e) => {
			this.introProgress = e.value
		})
		E.on('introEnter', this.onEnter)
		E.on('introLeave', this.onLeave)

		this.setMotoTimeline()
		E.on('setMotoConfig', (e) => {
			this.motoTimeline.progress(e)
			this.motoTimeline.pause()
		})
		E.on('leaveManifesto', () => {
			// console.log(this.motoTimeline.progress())
			this.motoTimeline.reverse()
		})
	}

	onLeave = () => {
		this.motoTimeline.play()
		const that = this
		if(this.isEnter){
			this.isEnter = false
			gsap.to(this.token1.position, { y: 0, duration: 1, ease: "power2.in"})
			gsap.to(this.token1.scale, { y: 30, x: 30, z: 30, duration: 1, ease: "power2.in"})
			gsap.fromTo(this.position, { x: 0, z: 0}, {x: -6, z: 13, duration : 1, ease: "power2.in"})
			gsap.fromTo(this.rotation, { y: 0}, {y: 5.14, duration : 1, ease: "power2.in", onUpdate: function() {
				that.motoTimeline.progress(this.progress())
			}})
		}
	}

	onEnter = (param) => {
		const that = this
		if(!this.isEnter){
			this.isEnter = true
			that.motoTimeline.progress(0)

			gsap.fromTo(this.position, { x: 6, z: 13}, {x: 0, z: 0, duration : 1, ease: "power2.out"})
			gsap.fromTo(this.rotation, { y: -5.14}, {y: 0, duration : 1, ease: "power2.out", onUpdate: function() {
				// if(param.page !== 'Contact-page' || param.page !== 'Manifesto-page'){
				// 	that.motoTimeline.progress(1 - this.progress())
				// 	that.motoTimeline.pause()
				// }
				
			}})
			if(param.page === 'Manifesto-page'){
				gsap.to(this.token1.position, { y: -4.5, duration: 0.4, onUpdate: function(){
					that.motoTimeline.pause()
					that.motoTimeline.progress(this.progress())
				}})
				
			} 
			else if(param.page === 'Contact-page'){
				// gsap.to(this.token1.scale, { y: 70, x: 70, z: 70, duration: 0.4})
				this.motoTimeline.pause()
				this.motoTimeline.progress(0)
				for (const [key, value] of Object.entries(this.baseConfig)) {
					gsap.fromTo(this.tokenMaterial.uniforms[key], { value: value}, { value: this.contactConfig[key]})
				}
			} 
			else {
				gsap.to(this.token1.position, { y: 0, duration: 0.5, onUpdate: () => {
				}})

			}


		} else {
			this.targetRotation += Math.PI
			gsap.to(this.token1.material.uniforms.uOffsetY, { value: this.targetRotation, duration: 1,
				onUpdate: function(){
					that.backfaceTokenMaterial.uniforms.uOffsetY = that.token1.material.uniforms.uOffsetY
					if(param.page !== 'Contact-page'){
						that.motoTimeline.progress(Math.sin(( this.progress()) * Math.PI))
						that.motoTimeline.pause()
					}
				}, 
			})
			if(param.page === 'Manifesto-page'){
				gsap.to(this.token1.position, { y: -4.5, duration: 1, onUpdate: function() {
					that.motoTimeline.progress(1)
					that.motoTimeline.pause()
				}})
			} else{
				gsap.to(this.token1.position, { y: 0, duration: 1})
			}

			if(param.page === 'Contact-page'){
				// gsap.to(this.token1.scale, { y: 70, x: 70, z: 70, duration: 1})
				for (const [key, value] of Object.entries(this.baseConfig)) {
					gsap.fromTo(this.tokenMaterial.uniforms[key], { value: value}, { value: this.contactConfig[key]})
				}
			} else {
				gsap.to(this.token1.scale, { y: 30, x: 30, z: 30, duration: 1})
			}

			gsap.to(this.token1.position, { z:  4, yoyo: true, repeat: 1, duration: 0.4})
		}
	}

	setMotoTimeline(){
		this.motoTimeline = gsap.timeline({ paused: true })
		for (const [key, value] of Object.entries(this.baseConfig)) {
			this.motoTimeline.fromTo(this.tokenMaterial.uniforms[key], { value: value}, { value: this.motoConfig[key]}, 0)
		}
		

	}

	appear() {
		// this.appearTimeline = gsap.timeline({ paused: true })
		// this.appearTimeline.addLabel('tokenAppear')
		// this.appearTimeline.fromTo(this.tokenMaterial.uniforms.uAppear, { value: 0}, { value: 2, duration: 4}, 'tokenAppear')
		// this.appearTimeline.to(this.token.rotation, { z: Math.PI * 2, duration: 1}, 'tokenAppear+=0.5')
		// this.appearTimeline.to(this.token.position, { y: 0, z: 4, duration: 1.2, ease: "back.out(5)"}, 'tokenAppear+=0.5')

		// this.appearTimeline.addLabel('gridAppear', '-=2')
		// this.appearTimeline.fromTo(this.gridMaterial.uniforms.uAppear, { value: 0},
		// 	{ 
		// 		value: 30,
		// 		duration: 2,
		// 		onUpdate: () => {
		// 			this.backfaceMaterial.uniforms.uAppear.value = this.gridMaterial.uniforms.uAppear.value
		// 		}
		// }, 'gridAppear-=.5')
		
		// this.tokenLeaveTimeline = gsap.timeline({ paused: true })
		// this.tokenLeaveTimeline.to(this.token.position, { y: 5.5, duration: 1.2}, 0)
		// this.tokenLeaveTimeline.to(this.tokenMaterial.uniforms.uIOR, { value: 1, duration: 1.2}, 0)
		// this.tokenLeaveTimeline.to(this.token.scale, { x: 1, y: 1, z: 1, duration: 1.2}, 0)
	}

	introLeave() {
		// if(this.tokenLeaveTimeline) {
		// 	this.tokenLeaveTimeline.progress(e)
		// }
		// this.gridMaterial.uniforms.uLeave.value = e * 6
		// this.backfaceMaterial.uniforms.uLeave.value = e * 6
		// // this.backfaceTokenMaterial.uniforms.uLeave.value = e
		// // this.token
		// // this.gridMaterial.uniforms.uLeave.value = e
	}


	createSingle(id) {
		const geometry = id === 0 ? this.token : this.token1
		this.backfaceTokenFbo = new WebGLRenderTarget(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio())
		this.envTokenFbo = new WebGLRenderTarget(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio())
		this.tokenMaterial = new TokenMaterial({
			backfaceMap: this.backfaceTokenFbo.texture,
			envMap: this.envTokenFbo.texture,
			// tNormal: this.normalMap,
			tMatCap: this.matCapToken,
			resolution: new Vector2(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio())
		})
		this.backfaceTokenMaterial = new BackFaceToken({
			// tNormal: this.normalMap,
		})
		if(id === 0) {
			this.token = new Mesh(geometry, this.backfaceTokenMaterial)
			this.token.rotation.set(0, 0, 0)
			this.token.position.set(0, 0, 0)
			this.token.scale.set(.8, .8, .8)
			this.token.scale.set(30, 30, 30)
			this.add(this.token)
		} else {
			this.token1 = new Mesh(geometry, this.backfaceTokenMaterial)
			this.token1.rotation.set(0, 0, 0)
			this.token1.position.set(0, 0, 0)
			this.token1.scale.set(.8, .8, .8)
			this.token1.scale.set(30, 30, 30)
			this.add(this.token1)
		}
		// this.token = new Mesh(geometry, this.backfaceTokenMaterial)
		// this.token.rotation.set(Math.PI * 0.5, 0, 0)
		// this.token.position.set(0, 0, 1)
		// this.token.scale.set(.8, .8, .8)
		// this.token.scale.set(30, 30, 30)
		// this.add(this.token)
	}
	mouseInteraction() {
		const hitplane = new Mesh(
			new PlaneGeometry(),
			new MeshBasicMaterial()
		) 
		hitplane.scale.setScalar(50)
		hitplane.position.set(0, 0, 0)
		// hitplane.rotation.x = -Math.PI/2
		hitplane.updateMatrix()
		hitplane.updateMatrixWorld()
		let raycaster = new Raycaster()
		// this.add(hitplane)

		this.mouse = new Vector2()
		let v2 = new Vector2()
		window.addEventListener('mousemove', (ev)=>{
			let x = ev.clientX / (window.innerWidth)  - 0.5
			let y = ev.clientY / (window.innerHeight)  - 0.5

			v2.x = x *2;
			v2.y = -y *2;
			raycaster.setFromCamera(v2, store.MainScene.camera)

			let intersects = raycaster.intersectObject(hitplane)

			if(intersects.length > 0){
				let first = intersects[0]
				this.mouse.x = first.point.x
				this.mouse.y = first.point.y
			}
		})
	}

	stop() {
		this.introProgressTemp = this.introProgress = 0
		store.RAFCollection.remove(this.movementEase)
		store.RAFCollection.remove(this.onRaf, 0)
		this.appearTimeline.seek(0)
		this.appearTimeline.pause()

		this.tokenLeaveTimeline.seek(0)
		this.tokenLeaveTimeline.pause()
		this.token.position.set(0, 0, -1)
		this.token.scale.set(2.5, 2.5, 2.5)
		this.gridMaterial.uniforms.uLeave.value = 0
		this.backfaceMaterial.uniforms.uLeave.value = 0

	}
	start() {
		// store.RAFCollection.add(this.movementEase)
		if(this.appearTimeline){
			this.appearTimeline.seek(0)
			this.appearTimeline.pause()
		}
		
		// store.RAFCollection.add(this.onRaf, 0)
		if(store.loaderOut){
			gsap.delayedCall(.5, () => {
				this.appearTimeline.play()
			})
		}
		
	}

	movementEase = () => {
		this.introProgressTemp += (this.introProgress - this.introProgressTemp ) * 0.3
		this.introLeave(this.introProgressTemp)
	}

	onRaf = () => {
		// Lerp uPos0 to mouse
		if(!store.isMobile){
			let v3 = new Vector2()
			v3.copy(this.mouse)
	
			v3.sub(this.tokenMaterial.uniforms.uPos0.value)
			v3.multiplyScalar(0.1)
			this.tokenMaterial.uniforms.uPos0.value.add(v3)
			// Get uPos1 Lerp speed 
			v3.copy(this.tokenMaterial.uniforms.uPos0.value)
			v3.sub(this.tokenMaterial.uniforms.uPos1.value)
			v3.multiplyScalar(0.1)
			// Lerp the speed
			v3.sub(this.vel)
			v3.multiplyScalar(0.9)
			this.vel.add(v3)
			// Add the lerped velocity
			if(!store.isMobile) {
				this.tokenMaterial.uniforms.uVel.value = this.vel.length() *2
				this.tokenMaterial.uniforms.uPos1.value.add(this.vel)
			}
			
			// this.tokenMaterial.uniforms.uPos0.value = this.gridMaterial.uniforms.uPos0.value
			this.backfaceTokenMaterial.uniforms.uPos0.value = this.tokenMaterial.uniforms.uPos0.value
		}
	


		store.WebGL.renderer.setRenderTarget(null)

		if(this.tokenChange){
			this.token1.visible = true
			// this.token.visible = false
	
			this.token1.material = this.backfaceTokenMaterial
			store.WebGL.renderer.setRenderTarget(this.backfaceTokenFbo)
			store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)
			this.token1.visible = false
			store.WebGL.renderer.setRenderTarget(this.envTokenFbo)
			store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)

			store.WebGL.renderer.setRenderTarget(null)
			store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)
			this.token1.visible = true
			this.token1.material = this.tokenMaterial

		} else {
			this.token.visible = true
			this.token1.visible = false
	
			this.token.material = this.backfaceTokenMaterial
			store.WebGL.renderer.setRenderTarget(this.backfaceTokenFbo)
			store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)
			this.token.visible = false
			store.WebGL.renderer.setRenderTarget(this.envTokenFbo)
			store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)

			store.WebGL.renderer.setRenderTarget(null)
			store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)
			this.token.visible = true
			this.token.material = this.tokenMaterial
		}

		// this.token.visible = true
	
		// this.token.material = this.backfaceTokenMaterial
		// store.WebGL.renderer.setRenderTarget(this.backfaceTokenFbo)
		// store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)
		// this.token.visible = false
		// store.WebGL.renderer.setRenderTarget(this.envTokenFbo)
		// store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)

		// store.WebGL.renderer.setRenderTarget(null)
		// store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)
		// this.token.visible = true
		// this.token.material = this.tokenMaterial

	}
	load() {
		store.AssetLoader.loadGltf(`/models/logo4.glb`).then(gltf => {
			// this.pieces = gltf.children
			this.token1 = gltf.scene.children[0].geometry
		})
		// store.AssetLoader.loadGltf(`/models/logo1.glb`).then(gltf => {
		// 	// this.pieces = gltf.children
		// 	this.token = gltf.scene.children[0].geometry
		// })
		// store.AssetLoader.loadFbx(`/model/token.fbx`).then(gltf => {
		// 	// this.pieces = gltf.children
		// 	this.token = gltf.children[0].geometry
		// })
		// store.AssetLoader.loadTexture(`/textures/tokenNormal1.png`, { flipY: false}).then(texture => {
		// 	this.normalMap = texture
		// })
	
		store.AssetLoader.loadTexture(`/textures/iridescent32.png`).then(texture => {
			this.matCapToken = texture
		})
	}

	onResize = () => {
		this.backfaceTokenFbo.setSize(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio())
		this.envTokenFbo.setSize(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio())
		
		this.tokenMaterial.uniforms.resolution.value = [store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio()]
		// this.backfaceMaterial.uniforms.resolution.value = [store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio()]
	}
}