<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="directors-list" id="to-canvas" :class="{ 'centered': directorCenter }">
		<ul ref="listContainer">
			<li v-for='(item, key) in directors' :key="key" ref="list">
				<div class="before"></div>
				<router-link :to="'talents/' + item.attributes.slug" ref="director" @mousemove="mouseDirector"
					@mouseenter="(e) => { hoverDirector(e, key) }" :class="{ pointerNone: velocity > 10 }"
					@mouseleave="leaveDirector" v-html="item.attributes.name.replaceAll(/\\n/g, '')"></router-link>
				<div class="after"></div>
				<video v-if="item.attributes.Thumbnail.data" ref="video"
					:src="apiUrl + item.attributes.Thumbnail.data.attributes.url" crossorigin="anonymous" muted
					playsinline loop></video>
			</li>
		</ul>
	</div>
</template>
<script>
import { E } from '@/assets/js/utils'
import store from '@/assets/js/store'
import {Vector2} from 'three'
import GlobalEvents from '@/assets/js/utils/GlobalEvents'
import axios from 'axios';
import gsap from 'gsap';
import { useRoute } from 'vue-router'

// import gsap from 'gsap'
export default {
  name: 'DirectorsList-comp',
  components: {},
  props: {
    data: Object
  },
  watch: {
	directors(){
		if(store.loaderIsOut){

			this.setDirectorsDisplay()
			setTimeout(() => {
		
			}, 100)
		}
		// this.$nextTick(() => {
		// 	store.Lenis.dimensions.resize()
		// 	if(store.isMobile){
		// 		this.setDirectorsDisplay()
		// 		this.addNames()
		// 	}
		// })
	}
  },
  data () {
    return {
		directors: [],
		apiUrl: process.env.VUE_APP_API,
		velocity: 0,
		directorCenter: false,
		idAdded: false
	}
  },
  async created () {
	this.route =  useRoute();
	if(store[this.route.name]) {
		this.directors  = store[this.route.name]
	} else {
		try {
			const response = await axios.get(`${process.env.VUE_APP_API}/api/directors?sort=rank:asc&populate=*`)
			this.directors = response.data.data
			store[this.route.name] = this.directors 
		} catch (error) {
			this.error = error;
		}
	}
  },
  mounted () {
	E.on(GlobalEvents.RESIZE, this.onResize)
	store.RAFCollection.add(this.onRaf, 0)
	this.route =  useRoute();
	if(!store.isLoaded){
		E.on('LoaderOut',() => {
			E.emit('introEnter', {page: this.route.name})
			store.MainScene.components.domText.adjustAscenderRatio = .14
			this.setDirectorsDisplay()
			// this.$nextTick(() => {
			// 	this.addNames()
			// })
			setTimeout(() => {
				E.emit('isPlainBackground', 0)
			}, 1)
		})
	} else {
		// E.emit('introEnter', {page: this.route.name})
		// setTimeout(() => {
		// 	E.emit('isPlainBackground', 0)
		// 	this.setDirectorsDisplay()
		// }, 1)
		store.MainScene.components.domText.adjustAscenderRatio = .14
	}
	
  },
  beforeUnmount(){
	E.off(GlobalEvents.RESIZE, this.onResize)
	this.$refs.director.forEach(el => {
		E.emit('domCanvasLeave', el.$el)
		E.emit('isPlainBackground', 1)
	})
  },
  methods: {
	addNames() {
		if(!store.isMobile){
			if(!store.isLoaded && !store.isMobile){
				E.on('PageLoaded',() => {
				// setTimeout(() => {
					this.$refs.director.forEach((el, i) => {
						el.size = el.$el.getBoundingClientRect()
						const listSize = this.$refs.list[i].getBoundingClientRect()
						this.$refs.list[i].style.setProperty('--left', el.size.left - listSize.left + 'px');
						this.$refs.list[i].style.setProperty('--right',  listSize.right - el.size.right + 'px');
						// E.emit('domCanvasAdd', { el: el.$el, opacity: 0, y: 100, x: 0, id: i, targetOpacity: 0.5})
						gsap.set(el.$el, {y: 100})
						gsap.to(el.$el, {y: 0, opacity: 0.5, delay: i * 0.05})
					})
				// }, 10)
			})
			} else {
				// this.$nextTick(() => {
					this.$refs.director.forEach((el, i) => {
						el.size = el.$el.getBoundingClientRect()
						const listSize = this.$refs.list[i].getBoundingClientRect()
						this.$refs.list[i].style.setProperty('--left', el.size.left - listSize.left + 'px');
						this.$refs.list[i].style.setProperty('--right',  listSize.right - el.size.right + 'px');
						// E.emit('domCanvasAdd', { el: el.$el, opacity: 0, y: 100, x: 0, id: i, targetOpacity: 0.5})
						gsap.set(el.$el, {y: 100})
						gsap.to(el.$el, {y: 0, opacity: 0.5, delay:  i * 0.05})
					})
				// })
			}
		} else {
			if(this.$refs.list){
				gsap.fromTo(this.$refs.list, {opacity: 0, y: 100}, {opacity: 1, y: 0, stagger: 0.1})
			}
		}
		
	},
	hoverDirector(e, key){
		if(this.hoverTimeout) clearTimeout(this.hoverTimeout);
		this.hoverTimeout = setTimeout(() => {
			E.emit('domCanvasOpacity', {el: e.target, value: 0})
			E.emit('setActiveVideo', this.$refs.video[key])
		}, 50)
	
	},
	leaveDirector(e){
		if(this.hoverTimeout) clearTimeout(this.hoverTimeout);
		E.emit('domCanvasOpacity', {el: e.target, value: .5})
	},
	mouseDirector(e){
		store.mousePos = new Vector2(e.clientX, e.clientY)
	},
	setDirectorsDisplay(){
		this.$nextTick(() => {
			const directorsWrapper = this.$refs.listContainer.getBoundingClientRect().height
			this.directorCenter = directorsWrapper < (store.window.h - 200)
			if(!this.idAdded){
				this.idAdded = true
				setTimeout(() => {
					this.addNames()
					store.Lenis.dimensions.resize()
				}, 500)
			}
		
		})
		
	},
	onResize() {
		this.setDirectorsDisplay()
		this.$refs.director.forEach(el => {
			E.emit('domCanvasRemove', el.$el)
		})
		this.addNames()
		this.$refs.director.forEach((el, i) => {
			el.size = el.$el.getBoundingClientRect()
			const listSize = this.$refs.list[i].getBoundingClientRect()
			this.$refs.list[i].style.setProperty('--left', el.size.left - listSize.left + 'px');
			this.$refs.list[i].style.setProperty('--right',  listSize.right - el.size.right + 'px');
		})
	},
	onRaf(){
		if(store.Lenis){
			this.velocity = Math.abs(store.Lenis.velocity)
		}
	}
  }
}
</script>
<style lang="less">
@import '../../assets/less/_imports.less';
@import "./style.less";
</style>