
import {Group, PlaneGeometry, Mesh, VideoTexture, Vector2} from 'three'
import { VideoMaterial } from '../materials'
import store from '../store'
import { qs, E } from '../utils'
import GlobalEvents from '../utils/GlobalEvents'
import gsap from 'gsap'
export default class VideoBackground extends Group{
	constructor() {
		super()
		this.load()
		E.on(GlobalEvents.RESIZE, this.onResize)
		
	}

	build() {
		this.createEl()
		this.addEvents()
		store.RAFCollection.add(this.onRaf, 4)

	}

	addEvents(){
		E.on('HomeMounted', this.pageSwitch)
		E.on('PageSwitch', this.pageSwitch)
		E.on('isPlainBackground', (e) => {
			gsap.to(this.item.material.uniforms.isPlainBackground, { value: e })
			// this.item.material.uniforms.isPlainBackground.value = e
		})
		E.on('setActiveVideo', (e) => {
			if(e){
				this.videoTexture = new VideoTexture(e)
				this.item.material.uniforms.uTexture.value = this.videoTexture
				e.play()
			}
			
		})
	} 
	load() {

	}

	pageSwitch = () => {
		const videoEl = qs('video#videoContainer')
		if (videoEl && this.item) {
			gsap.set(this.item.material.uniforms.isPlainBackground, { value: 1 })
			if(videoEl.readyState > 3) {
				gsap.to(this.item.material.uniforms.isPlainBackground, { value: 0 })
			} else {
				videoEl.addEventListener('canplaythrough', ()=>{
					gsap.to(this.item.material.uniforms.isPlainBackground, { value: 0 })
				})
			}
			videoEl.play()
			this.videoTexture = new VideoTexture(videoEl)
			this.item.material.uniforms.uTexture.value = this.videoTexture
		}
	}

	createEl() {
		const videoEl = qs('video#videoContainer')
		if (videoEl && !store.isLowPower) {
			this.videoTexture = new VideoTexture( qs('video#videoContainer'))
			videoEl.play()
		}
		const geometry = new PlaneGeometry()
		const material = new VideoMaterial({
			ratio: new Vector2(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio()),
			videoRatio: new Vector2(16, 9),
			videoTexture: { value: null }
		})
		this.item = new Mesh(geometry, material)
		this.item.scale.set(100, 100, 100)
		this.item.position.set(0, 0, -10)
		this.add(this.item)
		this.item.material.uniforms.uTexture.value = this.videoTexture 
	}
	onRaf = () => {
		// this.item.material.uniforms.uTexture.value = this.videoTexture 
	}

	onResize = () => {
		this.item.material.uniforms.uRatio.value = [store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio()]
	}
}