<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="contact-page" v-if="content.mail">
		<div class="content">
			<a v-for="(mail, key) in content.email" class="mail" :key="key" :href="`mailto:${mail.address}`">
				<span class="name domText">{{ mail.name }}</span>
				<span class="address domText">{{ mail.address }}</span>
			</a>
			<!-- <a class="mail" :href="`mailto:${content.mail.replaceAll('&amp;shy;', '')}`"
				v-html="setContent(content.mail)">
			</a> -->
			<a class="intagram domText" :href="content.Link.url" target="_blan" rel="noopener">{{ content.Link.text
				}}</a>
			<p class="" v-html="setContent(content.address)"></p>
		</div>
		<div class="credits"><a href="http://www.charlie-montagut.com" target="_blank" rel="noopener">site by
				charlie</a></div>
	</div>
</template>
<script>
import axios from 'axios';
import store from '@/assets/js/store'
import { E, qsa } from '@/assets/js/utils'
import moto from '@/assets/image/moto.png';
import { useRoute } from 'vue-router'
export default {
  name: 'contact-comp',
  components: {},
  props: {
    data: Object
  },
  watch: {
	content(){
		// this.addText()
		// setTimeout(() => {
		// 	this.addImage()
		// }, 500)
	}
  },
  data () {
    return {
		apiUrl: process.env.VUE_APP_API,
		content: [],
		moto: moto
	}
  },
  async created () {
	this.route =  useRoute();
	if(store[this.route.name]) {
		this.content = store[this.route.name]
	} else {
		try {
			const response = await axios.get(`${process.env.VUE_APP_API}/api/contact?populate=*`)
			this.content = response.data.data.attributes
			store[this.route.name] = this.content
		} catch (error) {
			this.error = error;
		}
	}
 
	setTimeout(() => {
		if(store.isLoaded) {
			store.Lenis.dimensions.resize()
		}
	}, 500)



	// setTimeout(() => {
	// 	if(store.isLoaded) {
	// 		store.Lenis.dimensions.resize()
	// 	}
	// }, 500)
	// try {
    //   const response = await axios.get(`${process.env.VUE_APP_API}/api/contact?populate=*`)
    //   this.content = response.data.data.attributes
    // } catch (error) {
    //   this.error = error;
    // }
  },
  mounted () {
	this.route =  useRoute();
	if(!store.isLoaded){
		E.on('LoaderOut',() => {
			setTimeout(() => {
				E.emit('isPlainBackground', 1)
			}, 1)
		})
	} else {
		setTimeout(() => {
			E.emit('isPlainBackground', 1)
		}, 1)
	}
  },
  beforeUnmount(){
	store.MainScene.components.domText.adjustAscenderRatio = -0.06
	this.items.forEach(el => {
		E.emit('domCanvasLeave', el)
	})
  },
  methods: {
	setContent(el){
		const spans = el.split('&shy;')
		let out = ''
		spans.forEach(el => {
			out += '<span class="domText">' + el + '</span>'
		})
		this.$nextTick(() => {
			this.addText(qsa('.domText'))
		})
		return out
	},
	addText(items) {
		store.MainScene.components.domText.adjustAscenderRatio = 0.15
		this.items = items
		if(!store.isLoaded){
			E.on('LoaderOut',() => {
			E.emit('introEnter', {page: this.route.name})
			setTimeout(() => {
				items.forEach((el, i) => {
					E.emit('domCanvasAdd', { el: el, opacity: 0, y: 0, x: 0, id: i})
					// gsap.to(this.$el, {opacity: 1})
				})
			}, 10)
		})
		} else {
			this.$nextTick(() => {
				items.forEach((el, i) => {
					setTimeout(() => {
						E.emit('domCanvasAdd', { el: el, opacity: 0, y: 0, x: 0, id: i})
					}, 100)
					// gsap.to(this.$el, {opacity: 1})
				})
			})
		}
	},
	addImage(){
		E.emit('domCanvasAdd', { el: this.$refs.moto, opacity: 0, y: 0, x: 0})
	}
  }
}
</script>
<style lang="less">
@import '../../assets/less/_imports.less';
@import "./style.less";
</style>