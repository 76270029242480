import { createApp } from 'vue'
import App from './App.vue'
// import AppTemp from './AppTemp.vue'
import { createRouter, createWebHistory} from 'vue-router'
import { AssetLoader, E, RAFCollection } from './assets/js/utils'
import GlobalEvents from './assets/js/utils/GlobalEvents'
import MainScene from './assets/js/scenes/MainScene'
import WebGL from './assets/js/WebGL'
import store from './assets/js/store'

import Home from '../src/pages/Home'
import Director from '../src/pages/Director'
import TalentsPage from '../src/pages/TalentsPage'
import Manifesto from '../src/pages/Manifesto'
import Contact from '../src/pages/Contact'
import NotFound from '../src/pages/NotFound'


const routes = [
	{
		path: '/',
		component: Home,
		name: 'Home-page',
		meta: { transition: 'fade'}
	},
	{
		path: '/talents',
		component: TalentsPage,
		name: 'Talent-page',
		meta: { transition: 'fade'}
	},
	{
		path: '/talents/:id',
		component: Director,
		name: 'Director-page',
		meta: { transition: 'fade'}
	},
	{
		path: '/manifesto',
		component: Manifesto,
		name: 'Manifesto-page',
		meta: { transition: 'fade'}
	},
	{
		path: '/contact',
		component: Contact,
		name: 'Contact-page',
		meta: { transition: 'fade'}
	},
	{
		path: '/:notFound',
		component: NotFound,
		name: '404-page',
		meta: { transition: 'fade'}
	},
]
const router = createRouter({
	history: createWebHistory(),
	routes,
})


createApp(App).use(router).mount('#app')


store.RAFCollection = new RAFCollection()
store.AssetLoader = new AssetLoader()

window.urlParams = new URLSearchParams(window.location.search)

store.WebGL = new WebGL()
store.MainScene = new MainScene()

GlobalEvents.detectTouchDevice()
GlobalEvents.enableRAF(true)
GlobalEvents.enableResize()
GlobalEvents.enableMousemove()


store.AssetLoader.load().then(() => {
	E.emit('App:start')
	store.isLoaded = true
	store.WebGL.buildPasses()

	if (new URLSearchParams(window.location.search).has('gui')) {
		import('./assets/js/utils/Gui').then(({ Gui }) => {
			store.Gui = new Gui()
		})
	}
})
