<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="project-component" v-if="activeProject.mediaList && open" :style="`background-color:${activeProject.mediaList[activeMedia].bgColor === 'white' ? '#FFFFFF' : '#000000'}`">
		<div v-if="activeProject.mediaList.length > 1" class="go-left" @click="nextProject(-1)">
		</div>
		<div v-if="activeProject.mediaList.length > 1" class="go-right" @click="nextProject(1)">
		</div>
		<div class="menu">
			<div class="left">
				<div class="name">
					{{ activeProject.name }}
				</div>
				
				<div class="credits" @click="toggleCredits" v-if="activeProject.Credits.length > 0">
					{{ this.showCredit ? 'close credits' : 'view credits' }}
				</div>
			</div>
			<div class="right">
				<button @click="close">close</button>
			</div>
		</div>
		<div class="credit-pannel" :class="{show: showCredit, center: creditCenter}" data-lenis-prevent ref="creditWrapper">
			<div class="credit-container" ref="creditContainer">
				<div class="credit-pannel-item" v-for="(item, key) in activeProject.Credits" :key="key" ref="people">
					<div class="credit-pannel-item-role" v-if="item.role">{{ item.role }}</div> 
					<div class="credit-pannel-item-names" v-if="item.name" v-html="item.name[0].children[0].text.replaceAll('\n', '</br>')"></div>
				</div>
			</div>
			
		</div>
		<!-- {{ activeProject.mediaList[activeMedia].__component }} -->
		<div class="video-container" :class="{blurred: showCredit}" v-if="activeProject.mediaList[activeMedia].__component === 'projects.video'">
			<videoplayer
				class="videoplayer"
				:src="activeProject.mediaList[activeMedia].link"
				:muted="false"
				:autoplay="true"
				:controls="false"
				:loop="false"
				@play="onPlayerPlay"
				@pause="onPlayerPause"
				@ended="onPlayerEnded"
				@loadeddata="onPlayerLoadeddata"
				@waiting="onPlayerWaiting"
				@playing="onPlayerPlaying"
				@timeupdate="onPlayerTimeupdate"
				@canplay="onPlayerCanplay"
				@canplaythrough="onPlayerCanplaythrough"
				@statechanged="playerStateChanged"
				>
				<template
					v-slot:controls="{
					togglePlay,
					playing,
					percentagePlayed,
					seekToPercentage,
					// duration,
					// convertTimeToDuration,
					videoMuted,
					toggleMute,
					fullScreen
					}"
				>
					<div class="videoplayer-controls">
					<button @click="togglePlay()" class="videoplayer-controls-toggleplay">
						{{ playing ? "pause" : "play" }}
					</button>
					<!-- <div class="videoplayer-controls-time">
						{{ convertTimeToDuration(time) }} /
						{{ convertTimeToDuration(duration) }}
					</div> -->
					<videoplayer-track
						:percentage="percentagePlayed"
						@seek="seekToPercentage"
						class="videoplayer-controls-track"
					/>
					<button @click="toggleMute()" class="videoplayer-controls-togglemute">
						{{ videoMuted ? "unmute" : "mute" }}
					</button>
					<button @click="fullScreen()" class="videoplayer-controls-togglemute">
						{{ isMobile ? 'fs' : 'fullscreen' }}
					</button>
					</div>
				</template>
			</videoplayer>
		</div>
		<div class="image-container" v-else>
			<img :src="apiUrl + activeProject.mediaList[activeMedia].image.data.attributes.url" alt="">
		</div>
	</div>
</template>
<script>

import videoplayer from "../videoplayer";
import videoplayerTrack from "../videoplayer-track";
import store from "@/assets/js/store";
import gsap from 'gsap'
import E from '@/assets/js/utils/E'
import GlobalEvents from '@/assets/js/utils/GlobalEvents'
export default {
  name: 'Project-comp',
  components: {
	videoplayer,
	videoplayerTrack
  },
  props: {
    activeProject: Object,
	open: Boolean,
	close: Function,
  },
  data () {
    return {
		activeMedia: 0,
		time: 0,
		showCredit: false,
		apiUrl: process.env.VUE_APP_API,
		isMobile: store.isMobile,
		creditCenter: false
	}
  },
  created () {
  },
  mounted () {
	if( this.$refs.creditWrapper && this.$refs.creditWrapper){
		this.setCreditDisplay()
	}
	E.on(GlobalEvents.RESIZE, this.onResize)
  },
  beforeUnmount(){
	E.off(GlobalEvents.RESIZE,this.onResize)
  },
  methods: {
	nextProject(val){
		let tempVal = this.activeMedia
		tempVal += val
		tempVal = tempVal % (this.activeProject.mediaList.length)
		if(tempVal < 0) tempVal = (this.activeProject.mediaList.length - 1)
		this.activeMedia = tempVal
		
	},
	toggleCredits(){
		this.showCredit = !this.showCredit
		if(this.showCredit){
			gsap.from(this.$refs.people, {opacity: 0, y: 50, stagger: 0.05})
		} else {
			gsap.killTweensOf(this.$refs.people)
			gsap.set(this.$refs.people, {opacity: 1, y: 0})
		}
	},
	setCreditDisplay(){
		const creditContaier = this.$refs.creditContainer.getBoundingClientRect().height
		const creditWrapper = this.$refs.creditWrapper.getBoundingClientRect().height
		this.creditCenter = creditContaier < creditWrapper
	},
	onPlayerPlay({player }) {
      player.setPlaying(true);
    },
    onPlayerPause({player }) {
      player.setPlaying(false);
    },
    onPlayerEnded({player }) {
      player.setPlaying(false);
    },
    onPlayerLoadeddata() {
    },
    onPlayerWaiting() {
    },
    onPlayerPlaying() {
    },
    onPlayerTimeupdate({event}) {
      this.time = event.target.currentTime;
    },
    onPlayerCanplay() {
    },
    onPlayerCanplaythrough() {
    },
    playerStateChanged() {
    },
	onResize(){
		if( this.$refs.creditWrapper && this.$refs.creditWrapper){
			this.setCreditDisplay()
		}
	}
  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>