<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="directors-page">
		<transition>
			<ProjectModal v-if="projectOpen" ref="modal" :open="projectOpen" :close="projectClose"
				:activeProject="activeProject" />
		</transition>
		<div class="textContent" ref="content">
			<h1>{{ content.name }} </h1>
			<a v-if="content.instagramLink" :href="content.instagramLink" target="_blank">instagram</a>
		</div>
		<ul class="projects" v-if="content.projects">
			<ProjectTease ref="projects" :data="project" v-for="(project, key) in content.projects.data"
				@click="(e) => { selectItem(e, key) }" :key="key" />
		</ul>
	</div>
</template>
<script>
import axios from 'axios';
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import ProjectTease from '@/components/ProjectTease'
import ProjectModal from '@/components/ProjectModal'
import {E} from '@/assets/js/utils'
import store from '@/assets/js/store'
export default {
  name: 'Directors-page',
  components: {
	ProjectTease,
	ProjectModal,
  },
  props: {
    data: Object,
	hideMenu: Function
  },
  data () {
    return {
		content: {},
		projects: [],
		activeProject: {},
		projectOpen: false,
		testName: 'Salomé Gomis-Trezise'
	}
  },
  mounted () {
	E.on('PageLoaded',() => {
		store.cursor.start()
		store.cursor.appear()

	})
	setTimeout(() => {
		if(store.isMobile){
			const size = this.$refs.content.getBoundingClientRect()
			this.$refs.projects.forEach((el, i) => {
				el.$el.style.height = el.$el.getBoundingClientRect().height - size.height + 'px'
				if(i === this.$refs.projects.length -1){
					el.$el.style.marginBottom = size.height + 'px'
				}
			});
		}
		if(store.isLoaded) {
			store.cursor.start()
			store.cursor.appear()
			store.Lenis.dimensions.resize()
		}
	
	}, 500)

	
  },

  async created () {
	this.route = useRoute()
	this.router = useRouter()
    try {
      const response = await axios.get(`${process.env.VUE_APP_API}/api/directors?filters[slug][$eq]=${this.route.params.id}&populate=deep`)
      this.content = response.data.data[0].attributes
    } catch (error) {
      this.error = error;
      this.router.push('/404')
    }

	// try {
    //   const response = await axios.get(`${process.env.VUE_APP_API}/api/projects?filters[director][slug][$eq]=${this.route.params.id}&sort=rank:asc&populate=deep`)
    //   this.projects = response.data.data
    // } catch (error) {
    //   this.error = error;
    // }
  },
  methods: {
	selectItem(e, i){
		this.projectOpen = true
		this.activeProject = this.projects[i].attributes
		this.hideMenu(true)
	},
	projectClose(){
		this.projectOpen = false
		this.$refs.modal.showCredit = false
		// this.activeProject = {}
		this.hideMenu(false)
	}
  }
}
</script>
<style lang="less">
@import '../../assets/less/_imports.less';
@import "./style.less";
</style>