<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="not-found-page">
		<div class="content">
			<div ref="code" class="code">{{ content.notFoundCode }}</div>
			<div ref="text" class="text">{{ content.notFoundText }}</div>
			<router-link to="/">{{ content.linkHomeText }}</router-link>
		</div>
		<video id="videoContainer" :src="content.VideoLink" crossorigin="anonymous" muted playsinline loop autoplay></video>
	</div>
</template>
<script>
import axios from 'axios';
import { E } from '@/assets/js/utils'
import store from '@/assets/js/store'
export default {
  name: '404-page',
  components: {},
  props: {
    data: Object
  },
  data () {
    return {
		content:{}
	}
  },
  async created () {
	try {
      const response = await axios.get(`${process.env.VUE_APP_API}/api/not-found-page`)
      this.content = response.data.data.attributes
      console.log(this.content)
    } catch (error) {
      this.error = error;
    }
  },
  mounted () {
	if(!store.isLoaded){
		E.on('LoaderOut',() => {
			E.emit('domCanvasAdd',{el:this.$refs.code})
			E.emit('domCanvasAdd',{el:this.$refs.text})
		})
	} else{
		setTimeout(() => {
			E.emit('domCanvasAdd',{el:this.$refs.code})
			E.emit('domCanvasAdd',{el:this.$refs.text})
		}, 500)
	
	}
	
  },
  methods: {
  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>